<template>
	<div>
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.block-item {
	background: var(--primary-background-color);
	padding: 75px 50px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.block-item:nth-child(even),
.block-item:nth-child(odd).reverse {
	flex-direction: row;
}

.block-item:nth-child(even).reverse {
	flex-direction: row-reverse;
}

.block-item:last-child {
	padding: 75px 50px;
}

@media (max-width: 960px) {
	.block-item,
	.block-item:nth-child(even),
	.block-item:nth-child(odd).reverse,
	.block-item:nth-child(even).reverse {
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.block-item,
	.block-item:last-child {
		padding: 0;
	}
}
</style>
