<template>
	<section v-if="reviews.length" class="main-content review-section">
		<div class="row">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12">
				<div ref="emblaCarousel" class="carousel review-slider">
					<div class="carousel-container">
						<div v-for="review in reviews" :key="review.ID" class="review-slide-content">
							<p>{{ review.content }}</p>
							<div class="border" />
							<div class="source">
								<strong>{{ review.firstName }}</strong>
								<span v-if="review.date">
									{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
								</span>
								<span class="source-logo" :class="review.source" />
							</div>
						</div>
					</div>
					<div class="carousel-controls">
						<button
							v-for="(_, index) in reviews"
							:key="index"
							:class="{ active: selectedScrollSnap === index }"
							@click="scrollCarouselTo(index)"
						></button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import emblaCarouselVue from 'embla-carousel-vue';

const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});

const [emblaCarousel, emblaApi] = emblaCarouselVue({ loop: true, duration: 20 });
const selectedScrollSnap = ref(0);

const scrollCarouselTo = (index) => {
	emblaApi.value.scrollTo(index);
	selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
};

onMounted(() => {
	if (!emblaApi.value) {
		return;
	}

	emblaApi.value.on('select', () => {
		selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
	});
});
</script>

<style lang="scss" scoped>
.review-slider {
	overflow: hidden;

	&::before {
		content: '';
		display: inline-block;
		width: 64px;
		height: 48px;
		background: url('~/assets/images/reviews-quotes.png');
		position: absolute;
		top: 65px;
		left: 130px;
	}

	.carousel-container {
		display: flex;
	}

	.carousel-controls {
		display: flex;
		justify-content: center;

		button {
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background-color: #d8d8d8;
			margin: 0 5px;
			cursor: pointer;
			border: none;
			box-shadow: none;

			&.active {
				background-color: #000;
			}
		}
	}

	.review-slide-content {
		flex: 0 0 100%;
		min-width: 0;
		padding: 25px 250px;
		flex-direction: column;
		text-align: left;
		user-select: none;

		.border {
			height: 1px;
			width: 100%;
			background-color: #efefef;
			margin: 10px 0 20px;
		}

		.source {
			width: 100%;
			display: flex;
			align-items: center;
			margin: 10px 0;

			span,
			strong {
				margin: 0 4px 0 0;
			}

			.source-logo {
				margin-left: auto;
				width: 100px;
				height: 67px;
				background-size: 100px 67px;

				&.tripadvisor {
					background-image: url('~/assets/images/review-logo-tripadvisor.png');
				}

				&.bookingcom {
					background-image: url('~/assets/images/review-logo-bookingcom.png');
				}

				&.zoover {
					background-image: url('~/assets/images/review-logo-zoover.png');
				}

				&.expedia {
					background-image: url('~/assets/images/review-logo-expedia.png');
				}

				&.google {
					background-image: url('~/assets/images/review-logo-google.png');
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.review-slider {
		&::before {
			display: none;
		}

		.review-slide-content {
			padding: 50px 90px;
		}
	}
}

@media (max-width: 768px) {
	.review-slider .review-slide-content {
		padding: 50px 70px;
	}
}

@media (max-width: 600px) {
	.review-slider .review-slide-content {
		padding: 20px;
	}
}
</style>
